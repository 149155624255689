import React from "react";
import { Box, Typography } from "@mui/material";
import menuBg from "../../Assets/menu-bg.jpg";
import HeroImage from "../../components/HeroImage/HeroImage";
import MenuContent from "./MenuContent/MenuContent";
import { Helmet } from "react-helmet";
const YehawMenu = () => {
  return (
    <Box>
      <HeroImage image={menuBg} text="Yehaw Menu" />
      <MenuContent />;
      <Helmet>
        <link rel="canonical" href="https://weddingbbq.co/yehawmenu" />
      </Helmet>
    </Box>
  );
};

export default YehawMenu;
