import { React } from "react";
import Hero from "./Hero/Hero";
import { Box } from "@mui/material";
import CustomImageList from "./CustomImage list/CustomImageList";
import HomeDetails from "./HomeDetails/HomeDetails";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <Box>
      <Hero />
      <HomeDetails />
      <CustomImageList />
      <Helmet>
        <link rel="canonical" href="https://weddingbbq.co/" />
      </Helmet>
    </Box>
  );
};

export default Home;
