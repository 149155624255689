import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#b71c1c",
      },
      secondary: {
        main: "#f8f9fa",
      },
      background: {
        default: "#212529",
        paper: "#212529",
      },
      text: {
        primary: "rgba(255,254,254,0.87)",
        secondary: "rgba(255,254,254,0.87))",
      },
    },
    typography: {
      h1: {
        fontFamily: ' "Ultra", serif;',
      },
      h2: {
        fontFamily: ' "Ultra", serif;',
      },
      h3: {
        fontFamily: ' "Ultra", serif;',
      },
      h4: {
        fontFamily: ' "Ultra", serif;',
      },
      h5: {
        fontFamily: ' "Ultra", serif;',
      },
      h6: {
        fontFamily: ' "Ultra", serif;',
      },
      p: {
        fontFamily: '"Ultra", serif',
      },
    },
  })
);

export default theme;
