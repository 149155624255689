import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import { orange, brown, red } from "@mui/material/colors";
import "@fontsource/merriweather";
import "@fontsource/ultra";
import "@fontsource/roboto";
import Image from "mui-image";

import stampRed from "../../../Assets/stamp-red.png";

import topBbqStamp from "../../../Assets/top-bbg-stamp.png";

const HomeDetails = () => {
  const getSeason = () => {
    const month = new Date().getMonth(); // Get current month (0-11)
    if (month >= 3 && month <= 5) {
      return "SPRING";
    } else if (month >= 6 && month <= 8) {
      return "SUMMER";
    } else if (month >= 9 && month <= 11) {
      return "AUTUMN";
    } else {
      return "WINTER";
    }
  };

  const currentSeason = getSeason(); // Get the current season

  return (
    <Box>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: orange[800],
        }}
      >
        <Typography variant="h3">
          Get Your BBQ Order Here: <br />
          682-262-7553
        </Typography>
        <Typography
          fontFamily="Ultra"
          variant="h3"
          sx={{ padding: "0 10%", lineHeight: "" }}
        >
          {currentSeason} SEASON
          <br /> Is Here..! <br />
          Let's BBQ!!
        </Typography>

        {/* <Typography fontFamily="Ultra" sx={{ padding: "0 30%" }} variant="h5">
          Fine Authentic Texas BBQ Holiday Meals are available with 48hrs
          advance notice.
        </Typography> */}
        <Button
          size="large"
          color="inherit"
          sx={{ width: "30%" }}
          variant="outlined"
          href="/menu"
        >
          View Menu
        </Button>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: 3,
          backgroundColor: brown[800],
        }}
      >
        <Typography fontFamily="Ultra" variant="h3">
          <Image
            duration="0"
            src={stampRed}
            style={{ height: "250px", width: "250px" }}
          />
          Authentic Premium Texas BBQ <br /> True Texas Flavor
        </Typography>
        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          Our Premium BBQ is delish tasting and crafted with love. We use
          premium meats and fine herbs and spices. Our meats are slowed cooked
          to perfection in-house over natural Texas hickory wood.
        </Typography>
        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          All of our catering comes with choices of sides made by our team of
          chefs. We offer coleslaw, homestyle potato salad, mashed potatoes,
          green beans, mac n cheese, stuff baked potatoes and beans. Our
          desserts are yummy as well with various pies selections and more.
          Catering can be picked up or delivered to your Wedding location.
          Holler Valerie and get a quote.
        </Typography>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: red[800],
        }}
      >
        <Typography fontFamily="Ultra" variant="h3">
          <Image
            duration="0"
            src={topBbqStamp}
            style={{ height: "280px", width: "300pxx" }}
          />
          Slow Smoked & <br /> Mouth Watering Delicious BBQ{" "}
        </Typography>
        <Typography fontFamily="Ultra" sx={{ padding: "0 10%" }} variant="h5">
          Congratulations To The Bride (Cowgirl) and Groom (Cowboy)!
        </Typography>
        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          Our wedding guests will enjoy Authentic Texas BBQ made by our
          co-Founder and Award-winning PitMaster, Robert Austin and co-Founder
          Valerie Austin. The Austin Family has catered Texas for over 30yrs.
          Robert and Valerie Austin team are from fine dining to pitmaster BBQ
          veterans. They have been in the restaurant industry for over 30yrs and
          serving the finest premium BBQ. For the LOVE of BBQ, their passion for
          fine BBQ foods and great service shows in their Texas BBQ and catering
          services. Get A Quote and start enjoying!! YEHAW!
        </Typography>
        <Button
          size="large"
          color="inherit"
          sx={{
            width: "25%",
          }}
          variant="outlined"
          href="/menu"
        >
          View Menu
        </Button>
      </Container>
    </Box>
  );
};

export default HomeDetails;
