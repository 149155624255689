import React from "react";
import theme from "./theme/Theme";
import Home from "./pages/home/Home";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import YehawMenu from "./pages/YehawMenu/YehawMenu";
import BbqQuote from "./pages/BbqQuote/BbqQuote";
import HollerHere from "./pages/HollerHere/HollerHere";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/yehawmenu" element={<YehawMenu />} />
        <Route path="/bbqquote" element={<BbqQuote />} />
        <Route path="/hollerhere" element={<HollerHere />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
