import React from "react";
import { Box } from "@mui/material";
import HeroImage from "../../components/HeroImage/HeroImage";
import HollerHereBg from "../../Assets/holler-here-bg.png";
import HollerHereContent from "./HollerHereContent/HollerHereContent";
import { Helmet } from "react-helmet";

const HollerHere = () => {
  return (
    <Box>
      <HeroImage image={HollerHereBg} text="Holler Here" />
      <HollerHereContent />
      <Helmet>
        <link rel="canonical" href="https://weddingbbq.co/hollerhere" />
      </Helmet>
    </Box>
  );
};

export default HollerHere;
