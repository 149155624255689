import React from "react";
import { Box, Typography } from "@mui/material";

const HeroImage = (props) => {
  return (
    <Box
      sx={{
        height: " 60vh",
        textAlign: "center",
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <Typography
        sx={{
          position: "relative",
          top: "70%",
          fontSize: { xs: "2rem", sm: "2.7rem", md: "3.2rem", lg: "3.7rem" },
        }}
        variant="h2"
      >
        {props.text}
      </Typography>
    </Box>
  );
};

export default HeroImage;
