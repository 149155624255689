import { Box } from "@mui/material";
import React from "react";
import HeroImage from "../../components/HeroImage/HeroImage";
import bbqImage from "../../Assets/bbq-quote-bg.jpg";
import BbqQuoteContent from "./BbqQuoteContent/BbqQuoteContent";
import { Helmet } from "react-helmet";

const BbqQuote = () => {
  return (
    <Box>
      <HeroImage image={bbqImage} text="Wedding BBQ Catering Quote" />
      <BbqQuoteContent />
      <Helmet>
        <link rel="canonical" href="https://weddingbbq.co/bbqquote" />
      </Helmet>
    </Box>
  );
};

export default BbqQuote;
