import { React, useEffect } from "react";
import { Container, Box, Typography } from "@mui/material";
import heroBg from "../../../Assets/hero-bg.jpg";
import "@fontsource/ultra";

const Hero = () => {
  return (
    <Box
      maxWidth="false"
      fixed
      sx={{
        height: "90vh",
        backgroundImage: `url(${heroBg})`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundAttachment: "fixed",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          textAlign: "center",
          position: "absolute",
          top: "35%",
          left: "0",
          right: "0",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2.5rem", sm: "5rem", md: "6rem" },
            paddingBottom: "15px",
            fontFamily: "ultra",
            textTransform: "uppercase",
          }}
        >
          Wedding BBQ <br /> Catering
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1.4rem",
              lg: "1.5rem",
              xl: "1.5rem",
            },
          }}
        >
          Make Your Wedding With BBQ
        </Typography>
      </Container>
    </Box>
  );
};

export default Hero;
